var mutations = {

  // 设置accessToken
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    localStorage.accessToken = accessToken
  },

  // 设置accessToken时间戳
  setAccessTokenExpires(state, accessTokenExpires) {
    state.accessTokenExpires = accessTokenExpires
    localStorage.accessTokenExpires = accessTokenExpires
  },

  // 设置refreshToken
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken
    localStorage.refreshToken = refreshToken
  },

  // 设置refreshToken时间戳
  setRefreshToken(state, refreshTokenExpires) {
    state.refreshTokenExpires = refreshTokenExpires
    localStorage.refreshTokenExpires = refreshTokenExpires
  },

  // 设置token类型
  setTokenType(state, tokenType) {
    state.tokenType = tokenType
    localStorage.tokenType = tokenType
  },

  // 菜单
  setMenuList(state, val) {
    state.menu_list = val
  },

  // 街道
  setChannelId(state, val) {
    state.channel_id = val
  },

  // 
  setName(state, val) {
    state.username = val
  },

  // 用户类型
  setUserType(state, val) {
    state.user_type = val
  },





}



export default mutations