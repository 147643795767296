/*import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);


// 引入：
import state from './state.js'
import getters from './getters.js'
import actions from './actions.js'
import mutations from './mutations.js'

const store = new Vuex.Store({
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
})

export default store*/

//import { createStore, createLogger } from 'vuex'
import { createStore } from 'vuex'

import state from './state.js'
import getters from './getters.js'
import actions from './actions.js'
import mutations from './mutations.js'

export default createStore({
  state,
  getters,
  actions,
  mutations,
 /* plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []*/
})