var getters = {
  // 获取accessToken
  getAccessToken(state) {
    console.log(state.accessToken)
    if (!state.accessToken) {
      console.log(1)
      state.accessToken = localStorage.getItem('accessToken')
    }
    return state.accessToken
  },
  // 获取accessToken时间戳
  getAccessTokenExpires(state) {
    if (!state.accessTokenExpires) {
      state.accessTokenExpires = localStorage.getItem('accessTokenExpires')
    }
    return state.accessTokenExpires
  },
  // 获取refreshToken
  getRefreshToken(state) {
    if (!state.refreshToken) {
      state.refreshToken = localStorage.getItem('refreshToken')
    }
    return state.refreshToken
  },
  // 获取refreshToken时间戳
  getRefreshToken(state) {
    if (!state.refreshTokenExpires) {
      state.refreshTokenExpires = localStorage.getItem('refreshTokenExpires')
    }
    return state.refreshTokenExpires
  },
  // 获取token类型
  getTokenType(state) {
    if (!state.tokenType) {
      state.tokenType = localStorage.getItem('tokenType')
    }
    return state.tokenType
  },
}


export default getters