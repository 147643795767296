var state = {
  accessToken: '', //访问token
  accessTokenExpires: '', //有效时间戳
  refreshToken: '', //刷新token
  refreshTokenExpires: '', //刷新token有效时间戳
  tokenType: '', //token类型

  username: '',
  channel_id: '',
  user_type: '', // 是否管理员
  menu_list: []
}



export default state